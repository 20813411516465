.checkbox {
  width: 100%;
  /* margin: 15px auto; */
  position: relative;
  display: block;
}

.checkbox input[type='checkbox'] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}
.checkbox label {
  position: relative;
}
.checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 0px;
  width: 18px;
  height: 18px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  border: 2px solid #707070;
}
.checkbox label:after {
  content: '';
  display: block;
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  -webkit-transform: rotate(-45deg) scale(0);
  transform: rotate(-45deg) scale(0);
  transition: transform ease 0.25s;
  will-change: transform;
  position: absolute;
  top: 4.5px;
  left: 4px;
}
.checkbox input[type='checkbox']:checked ~ label::before {
  color: #fff;
  border: 2px solid #ea6000;
}

.checkbox input[type='checkbox']:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.checkbox input[type='checkbox']:checked + label:before {
  background: #ea6000;
  text-align: center;
}

.checkbox label {
  min-height: 34px;
  display: block;
  padding-left: 40px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  vertical-align: sub;
}
.checkbox label span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.checkbox input[type='checkbox']:focus + label::before {
  outline: 0;
}
