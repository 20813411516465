@font-face {
  font-family: 'Montserrat';
  src: url('./Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('./Montserrat/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('./Montserrat/Montserrat-SemiBold.ttf');
}
